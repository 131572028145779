.contact-phone {
    font-size: 0.9rem;
}

.contact-address {
    font-size: 0.9rem;
    font-style: italic;
}

.contact-created-date {
    font-size: 0.8rem;
}

.contact-button-action {
    margin-bottom: 3px;
    font-size: 0.8em;
    padding: 4px 9px;
    width: 100%;
}

.container .btn i {
    margin-right: 5px;
    font-size: 1rem;
}

.followup_container {
    margin-top: 20px;
    border-top: #00000038 1px solid;
}

.followup_icon {
    position: relative;
    left: 49%;
    margin-top: 2%;
    cursor: pointer;
}

.container .col-sm {
    padding: 0;
}

button.full {
    width: 100%;
}

.container button.link {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-right: 5px
}

.action-icons-container {
    display: flex;
    justify-content: space-evenly;
}

.action-icons-container.divider {
    padding-bottom: 4px;
    border-bottom: #00000038 1px solid;
}

.bounce {
    animation: mover 0.3s infinite alternate;
    transform: translateY(10px)
}

.list_item_button {
    font-size: 0.8rem;
    margin-top: 5px;
}

.list_item_button i {
    margin-right: 5px;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}