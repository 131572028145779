@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.p40 {
  padding: 0px 40px !important;
}

.action-icon {
  color: gray;
  cursor: pointer;
  transition: color 0.5s ease;
}

.action-icon:hover {
  color: #424242;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.modal {
  background-color: rgba(0, 0, 0, 0.50);
}

.modal-content {
  border: none !important;
}

.contact-email {
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: normal;
}

.icon-color {
  color: #5d5d5d;
}

.icon-mr5 {
  margin-right: 5px;
}

.card {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.new-list-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.card-sub-title {
  background-color: #e8e8e8;
  padding: 10px;
  font-size: 1.05rem;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-full-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.bold {
  font-weight: bold;
}

.container {
  max-width: 100%;
}

@media print {

  .printable,
  .printable * {
    visibility: visible;
    font-size: 0.9em;
  }

  .non-printable,
  body * {
    visibility: hidden;
  }

  .non-printable {
    display: none;
  }
}
.contact-phone {
    font-size: 0.9rem;
}

.contact-address {
    font-size: 0.9rem;
    font-style: italic;
}

.contact-created-date {
    font-size: 0.8rem;
}

.contact-button-action {
    margin-bottom: 3px;
    font-size: 0.8em;
    padding: 4px 9px;
    width: 100%;
}

.container .btn i {
    margin-right: 5px;
    font-size: 1rem;
}

.followup_container {
    margin-top: 20px;
    border-top: #00000038 1px solid;
}

.followup_icon {
    position: relative;
    left: 49%;
    margin-top: 2%;
    cursor: pointer;
}

.container .col-sm {
    padding: 0;
}

button.full {
    width: 100%;
}

.container button.link {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-right: 5px
}

.action-icons-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.action-icons-container.divider {
    padding-bottom: 4px;
    border-bottom: #00000038 1px solid;
}

.bounce {
    -webkit-animation: mover 0.3s infinite alternate;
            animation: mover 0.3s infinite alternate;
    -webkit-transform: translateY(10px);
            transform: translateY(10px)
}

.list_item_button {
    font-size: 0.8rem;
    margin-top: 5px;
}

.list_item_button i {
    margin-right: 5px;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}
.list-container .card {
    margin-bottom: 25px;
}

.list-container .card-body {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.list-container .item { 
    margin-bottom: 5px;
}

.list-container .coursesContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.list-container .col:not(:first-child) {
    text-align: center;
}

.list-container .col {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.list-container .nameLink {
    text-decoration: none;
    text-transform: capitalize;
}
.big {
    width: 900px;
    max-width: 900px;
}

.medium {
    width: 50%;
    max-width: 50%;
}

.action-buttons-container {
    margin-top: 5px;
}
.filterContainer {
    margin: 30px 0;
    -webkit-align-items: center;
            align-items: center;
}

.filterContainer > div {
    -webkit-align-items: center;
            align-items: center;
}

.lateralMargin30 {
    margin: 0 30px;
}

.lateralMargin10 {
    margin: 0 10px;
}
.Card_titleField__235If {
    text-align: right;
    font-size: 0.95rem;
    font-weight: bold;
    padding-right: 5px;
    max-width: 120px;
    vertical-align: middle;
}

.Card_infoField__1V91Q {
    font-size: 0.95rem;
}

.Card_deliveredDocumentsContainer__36uOE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.Card_deliveredDocumentsContainer__36uOE > label{
    margin-top: 5px;
}
.Print_tableTitle__2eoTq {
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
    width: 300px;
    padding-right: 10px;
}

.Print_tableHeader__sTdVc {
    font-size: 1.3rem;
    padding: 5px;
    font-weight: bold;
}

.Print_tableHeaderContainer__2hbJ1 {
    text-align: center;
    margin: 20px 0 10px 0;
}

.Print_fieldContainer__2FaVl {
    border-bottom: 1px solid black;
    font-size: 1.1rem;
    margin-right: 20px;
    padding: 10px 0px;
}

.Print_signature__3ek2U {
    border-top: 1px solid gray;
    font-size: 21px;
    padding: 10px 40px;
}

.Print_printInfoContainer__3B0B8 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Print_printInfoContainer__3B0B8 table {
    width: 50%;
}

footer {
    font-size: 0.7rem;
}

img {
    max-width: 100%;
    width: 40%;
    margin-top: 0;
}

.Print_printInfoContainer__3B0B8 table {
    width: auto;
}

.Print_tableTitle__2eoTq {
    height: 15px;
    width: auto;
}

.Print_printInfoContainer__3B0B8 {
    font-size: 1.1rem;
}

@media print {

    html,
    body {
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
}
.big {
    width: 80%;
    max-width: 80%;
}

.action-buttons-container {
    margin-top: 5px;
}

.centered-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  -webkit-justify-content: center;
          justify-content: center;
}

.over-title {
  position: relative;
  z-index: 50;
  text-align: center;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.survey-card {
    margin: 2%;
}

.survey-container textarea, .rating {
    margin: 0 0 1% 0;
}

.full-star {
    color: #e85c0c;
}

.teachers-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.teacher-content {
    max-width: 40%;
    font-size: 0.9rem;
    margin-left: 0;
}

.rating-teacher-name {
    font-weight: bold;
}

.survey-container {
    padding: 10px;
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
}

.checkboxs-container {
    display: -webkit-flex;
    display: flex;
    margin: 10px 0;
}

.editButtons {
    margin-left: 1%;
}
.ExtensionChanger_extensionGroup__3ZCOh {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2%;
}

.ExtensionChanger_extensionGroup__3ZCOh>label {
    width: 40%;
    margin: 0 5px;
}

.ExtensionChanger_extensionContainer__Bgm_h {
    margin-top: 20px;
    border-top: 1px solid #bfbfbf;
    padding-top: 20px;
}


.ExtensionChanger_extensionButton__1aqS1 {
    width: 100%;
}
.flexSpacedAlignedContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 40%;
    -webkit-align-items: center;
            align-items: center;
}
div.certificateContainer:not(:first-of-type) {
    margin-top: 5%;
}

div.certificateContainer {
    width: 60%;
    padding: 2%;
    background-repeat: no-repeat !important;
    box-shadow: 0px 0px 14px -10px black;
    border-radius: 2%;
    background-position: center;
    background-size: cover;
}

.certificateContainer table {
    font-size: 0.8em;
}

.certificateContainer p {
    font-size: 0.8em;
}

.certificateContainer header {
    margin-bottom: 2%;
}

.certificateContainer header img {
    width: 30%
}

.cursor-pointer {
    cursor: pointer;
}

.print-icon {
    float: right;
}

.rotate-icon {
    float: left;
}

#section-to-print {
    overflow: hidden;
}

#section-to-print table {
    font-size: 12.8px;
}

.large-certificate {
    width: 100% !important;
}

.large-certificate ul {
    margin-top: -5px;
    margin-bottom: 15px;
}

.panel {
    width: 20%;
    background-color: #fcfcfc;
    padding: 2%;
    border-radius: 5px;
    box-shadow: 0px 0px 14px -10px black;
}

@media print {

    * {
        -webkit-print-color-adjust: exact !important;
                color-adjust: exact !important;
    }

    @page {
        margin: 0mm;
    }

    @page :footer {
        display: none
    }

    @page :header {
        display: none
    }

    #image-logo {
        width: 20%;
        margin-bottom: 5%;
    }

    #section-to-print * {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    #section-to-print {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        color: black;
    }

    #section-to-print ul {
        margin-top: 5%;
        margin-bottom: 1%;

        font-size: 1em;
    }

    p span {
        font-size: 0.5em;
    }
}
.Occupations_container__1cCrw {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Occupations_container__1cCrw .Occupations_tag__1KTFt {
    font-size: 0.9em;
    margin: 2px 8px;
    background-color: #6c757d;
}

.Occupations_container__1cCrw .Occupations_tag__1KTFt .Occupations_icon__3wg4f {
    color: white;
    margin-left: 5px;
}
.Education_styles_splitContainer__19kKg {
    width: 50%;
}

.Education_styles_container__1E7BL {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Education_styles_container__1E7BL .Education_styles_tag__3vIQc {
    font-size: 0.9em;
    margin: 2px 8px;
    background-color: #6c757d;
}

.Education_styles_container__1E7BL .Education_styles_tag__3vIQc .Education_styles_icon__2BR85 {
    color: white;
    margin-left: 5px;
}

.Education_styles_buttonsContainer__1PzHb{
    position: absolute;
    right: 1%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.Education_styles_buttonsContainer__1PzHb > button{
    margin-top: 2px;
}
