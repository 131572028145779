.splitContainer {
    width: 50%;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container .tag {
    font-size: 0.9em;
    margin: 2px 8px;
    background-color: #6c757d;
}

.container .tag .icon {
    color: white;
    margin-left: 5px;
}

.buttonsContainer{
    position: absolute;
    right: 1%;
    display: flex;
    flex-direction: column;
}
.buttonsContainer > button{
    margin-top: 2px;
}