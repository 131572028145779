.tableTitle {
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
    width: 300px;
    padding-right: 10px;
}

.tableHeader {
    font-size: 1.3rem;
    padding: 5px;
    font-weight: bold;
}

.tableHeaderContainer {
    text-align: center;
    margin: 20px 0 10px 0;
}

.fieldContainer {
    border-bottom: 1px solid black;
    font-size: 1.1rem;
    margin-right: 20px;
    padding: 10px 0px;
}

.signature {
    border-top: 1px solid gray;
    font-size: 21px;
    padding: 10px 40px;
}

.printInfoContainer {
    display: flex;
    justify-content: space-between;
}

.printInfoContainer table {
    width: 50%;
}

footer {
    font-size: 0.7rem;
}

img {
    max-width: 100%;
    width: 40%;
    margin-top: 0;
}

.printInfoContainer table {
    width: auto;
}

.tableTitle {
    height: 15px;
    width: auto;
}

.printInfoContainer {
    font-size: 1.1rem;
}

@media print {

    html,
    body {
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
}