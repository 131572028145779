.titleField {
    text-align: right;
    font-size: 0.95rem;
    font-weight: bold;
    padding-right: 5px;
    max-width: 120px;
    vertical-align: middle;
}

.infoField {
    font-size: 0.95rem;
}

.deliveredDocumentsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.deliveredDocumentsContainer > label{
    margin-top: 5px;
}