div.certificateContainer:not(:first-of-type) {
    margin-top: 5%;
}

div.certificateContainer {
    width: 60%;
    padding: 2%;
    background-repeat: no-repeat !important;
    box-shadow: 0px 0px 14px -10px black;
    border-radius: 2%;
    background-position: center;
    background-size: cover;
}

.certificateContainer table {
    font-size: 0.8em;
}

.certificateContainer p {
    font-size: 0.8em;
}

.certificateContainer header {
    margin-bottom: 2%;
}

.certificateContainer header img {
    width: 30%
}

.cursor-pointer {
    cursor: pointer;
}

.print-icon {
    float: right;
}

.rotate-icon {
    float: left;
}

#section-to-print {
    overflow: hidden;
}

#section-to-print table {
    font-size: 12.8px;
}

.large-certificate {
    width: 100% !important;
}

.large-certificate ul {
    margin-top: -5px;
    margin-bottom: 15px;
}

.panel {
    width: 20%;
    background-color: #fcfcfc;
    padding: 2%;
    border-radius: 5px;
    box-shadow: 0px 0px 14px -10px black;
}

@media print {

    * {
        color-adjust: exact !important;
    }

    @page {
        margin: 0mm;
    }

    @page :footer {
        display: none
    }

    @page :header {
        display: none
    }

    #image-logo {
        width: 20%;
        margin-bottom: 5%;
    }

    #section-to-print * {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    #section-to-print {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        color: black;
    }

    #section-to-print ul {
        margin-top: 5%;
        margin-bottom: 1%;

        font-size: 1em;
    }

    p span {
        font-size: 0.5em;
    }
}