.list-container .card {
    margin-bottom: 25px;
}

.list-container .card-body {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.list-container .item { 
    margin-bottom: 5px;
}

.list-container .coursesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.list-container .col:not(:first-child) {
    text-align: center;
}

.list-container .col {
    flex-wrap: wrap;
}

.list-container .nameLink {
    text-decoration: none;
    text-transform: capitalize;
}