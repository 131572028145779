.filterContainer {
    margin: 30px 0;
    align-items: center;
}

.filterContainer > div {
    align-items: center;
}

.lateralMargin30 {
    margin: 0 30px;
}

.lateralMargin10 {
    margin: 0 10px;
}