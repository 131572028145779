.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container .tag {
    font-size: 0.9em;
    margin: 2px 8px;
    background-color: #6c757d;
}

.container .tag .icon {
    color: white;
    margin-left: 5px;
}