body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.p40 {
  padding: 0px 40px !important;
}

.action-icon {
  color: gray;
  cursor: pointer;
  transition: color 0.5s ease;
}

.action-icon:hover {
  color: #424242;
}

.flex {
  display: flex;
}

.modal {
  background-color: rgba(0, 0, 0, 0.50);
}

.modal-content {
  border: none !important;
}

.contact-email {
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: normal;
}

.icon-color {
  color: #5d5d5d;
}

.icon-mr5 {
  margin-right: 5px;
}

.card {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.new-list-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-5 {
  margin-right: 5px;
}

.card-sub-title {
  background-color: #e8e8e8;
  padding: 10px;
  font-size: 1.05rem;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-full-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.bold {
  font-weight: bold;
}

.container {
  max-width: 100%;
}

@media print {

  .printable,
  .printable * {
    visibility: visible;
    font-size: 0.9em;
  }

  .non-printable,
  body * {
    visibility: hidden;
  }

  .non-printable {
    display: none;
  }
}