@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
.survey-card {
    margin: 2%;
}

.survey-container textarea, .rating {
    margin: 0 0 1% 0;
}

.full-star {
    color: #e85c0c;
}

.teachers-container {
    display: flex;
    justify-content: space-between;
}

.teacher-content {
    max-width: 40%;
    font-size: 0.9rem;
    margin-left: 0;
}

.rating-teacher-name {
    font-weight: bold;
}

.survey-container {
    padding: 10px;
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
}

.checkboxs-container {
    display: flex;
    margin: 10px 0;
}

.editButtons {
    margin-left: 1%;
}