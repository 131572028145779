.extensionGroup {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.extensionGroup>label {
    width: 40%;
    margin: 0 5px;
}

.extensionContainer {
    margin-top: 20px;
    border-top: 1px solid #bfbfbf;
    padding-top: 20px;
}


.extensionButton {
    width: 100%;
}