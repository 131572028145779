.big {
    width: 900px;
    max-width: 900px;
}

.medium {
    width: 50%;
    max-width: 50%;
}

.action-buttons-container {
    margin-top: 5px;
}